






import { defineComponent, computed } from '@vue/composition-api';
import ITextField from './ITextField.vue';

export default defineComponent({
  components: {
    ITextField,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    errorLabel: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const innerValue = computed({
      get() {
        return props.value;
      },
      set(newVal) {
        emit('input', newVal);
      },
    });

    return {
      innerValue,
    };
  },
});
