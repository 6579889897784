import { readonly, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { pinia } from './';
import { useUserStore } from './user';
import { countEventLikes, isEventLiking } from '@/js/lib/api/event';

const userStore = useUserStore(pinia);

export const useEventStore = defineStore('event', () => {
  const likedCounts = ref<{
    [key: string]: number;
  }>({});
  const likedEventIds = ref<number[]>([]);
  async function initLikedData(eventIds: number[]) {
    likedCounts.value = await countEventLikes({ eventIds });
    if (userStore.isAuthenticated) {
      const likedList = await isEventLiking({ eventIds });
      likedEventIds.value = likedList.filter((event) => event.liked === 1).map((event) => Number(event.eventId));
    }
  }
  function addLikedEventId(eventId: number) {
    likedCounts.value = likedCounts.value[eventId] ? { ...likedCounts.value, [eventId]: likedCounts.value[eventId] + 1 } : { ...likedCounts.value, [eventId]: 1 };
    if (!likedEventIds.value.includes(eventId)) {
      likedEventIds.value.push(eventId);
    }
  }
  function removeLikedEventId(eventId: number) {
    likedCounts.value = { ...likedCounts.value, [eventId]: likedCounts.value[eventId] - 1 };
    likedEventIds.value.splice(likedEventIds.value.indexOf(eventId), 1);
  }

  return { likedCounts, likedEventIds: readonly(likedEventIds), initLikedData, addLikedEventId, removeLikedEventId };
});
