import '@/js/dist/www.iichi.com/common';

import Vue from 'vue';
import { pinia } from '@/js/lib/store';
import { useItemStore } from '@/js/lib/store/item';
import { useEventStore } from '@/js/lib/store/event';
import { uniq } from '@/js/lib/helper/array';
import { addFollowButton } from '@/js/lib/ui/follow-button';
import Slider from '@/js/components/common/ui/slider.vue';
import LikeButtonSmall from '@/js/components/www.iichi.com/organisms/likeButtonSmall.vue';
import EventLikeButtonSmall from '@/js/components/www.iichi.com/organisms/eventLikeButtonSmall.vue';
import MessageButton from '@/js/components/listing/MessageButton.vue';

const eventStore = useEventStore(pinia);

document.addEventListener('DOMContentLoaded', async () => {
  await addFollowButton();

  if (document.querySelector('message-button')) {
    new Vue({
      el: 'message-button',
      components: {
        MessageButton,
      },
    });
  }

  const sliderEl = document.querySelector('#about-me-slide');
  if (sliderEl) {
    new Vue({
      el: sliderEl,
      components: {
        Slider,
      },
    });
  }

  const listEl = document.querySelector('#main .listing');
  if (listEl) {
    const itemStore = useItemStore(pinia);
    const itemIds = Array.from(document.querySelectorAll('like-button-small'), (el) => Number(el.getAttribute(':item-id')));
    itemStore.initLikedData(uniq(itemIds));

    new Vue({
      el: listEl,
      components: {
        LikeButtonSmall,
      },
    });
  }

  const eventEl = document.querySelector('#events');
  if (eventEl) {
    const eventIds = Array.from(document.querySelectorAll('event-like-button-small'), (el) => Number(el.getAttribute(':event-id')));
    eventStore.initLikedData(uniq(eventIds));

    new Vue({
      el: eventEl,
      components: {
        EventLikeButtonSmall,
      },
    });
  }
});
