
































import { defineComponent, computed } from '@vue/composition-api';
import { Input as ElInput, Autocomplete as ElAutocomplete } from 'element-ui';
import { hankaku } from '@/js/lib/helper/hankaku';
import axios from '@/js/lib/axios';

export default defineComponent({
  components: {
    ElInput,
    ElAutocomplete,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    errorLabel: {
      type: String,
      default: '',
    },
    hankaku: {
      type: Boolean,
      default: false,
    },
    apiPath: {
      type: String,
      default: '',
    },
    bankName: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const max = computed(() => {
      const maxRule = props.rules.split('|').find((item) => item.startsWith('max:'));
      return maxRule ? maxRule.replace('max:', '') : '';
    });
    const innerValue = computed({
      get() {
        return props.hankaku ? hankaku(String(props.value)) : String(props.value);
      },
      set(newVal) {
        emit('input', props.hankaku ? hankaku(newVal) : newVal);
      },
    });
    const querySearch = async (q: string, cb: (params: { value: string }[]) => void) => {
      const res = await axios.get(props.apiPath, { params: props.apiPath === '/api/bank/branch' ? { q, searchShop: true, bankName: props.bankName } : { q } });
      cb(
        res.data
          ? res.data.split('\n').map((value: string) => {
              return { value };
            })
          : [],
      );
    };

    return {
      max,
      innerValue,
      querySearch,
    };
  },
});
