import { sleep } from './sleep';

export const NativeView = {
  CART: 'cart',
  // FAVORITES: 'favorites',
  HOME: 'home',
  LOGOUT: 'logout',
  MENU: 'menu',
  // NOTIFICATIONS: 'notifications',
  // SEARCH: 'search',
  WEBVIEW: 'webview',
} as const;
export type NativeView = (typeof NativeView)[keyof typeof NativeView];

const pathForNativeView: Record<NativeView, string> = {
  [NativeView.CART]: '/tab/custom/99e36ae6',
  // [NativeView.FAVORITES]: '/favorites',
  [NativeView.HOME]: '/tab/scrollmenu/7e1998d7',
  [NativeView.LOGOUT]: '/tab/custom/ef34845e',
  [NativeView.MENU]: '/tab/custom/8467c75f',
  // [NativeView.NOTIFICATIONS]: '/notifications',
  // [NativeView.SEARCH]: '/search',
  [NativeView.WEBVIEW]: '/tab/custom/6fc8a457',
};

const isWebView = () => window.navigator.userAgent.includes('Yappli');

export const urlForDeepLink = (view: NativeView, params: Record<string, string> = {}): string => {
  const schema = isWebView() ? 'native:' : 'iichi-app:';
  const url = new URL(`${schema}${pathForNativeView[view]}`);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
  return url.toString();
};

const sendRegisteredId = async (memberId: string, token: string): Promise<void> =>
  new Promise((resolve, reject) => {
    window.Yappli.sendRegisteredId(JSON.stringify({ registered_id: memberId, token }), (result, message) => {
      if (result) {
        resolve();
      } else {
        reject(message);
      }
    });
  });

export const syncMemberIdToYappli = async (memberId: string, token: string, retry: number = 3): Promise<void> => {
  let i = 0;
  while (i <= retry) {
    try {
      await sendRegisteredId(memberId, token);
      return;
    } catch (error) {
      if (window.Sentry) {
        window.Sentry.captureException(error);
      }
      i++;
      await sleep(1000 * i ** 2); // リトライ間隔を 1, 4, 9 秒 にする
    }
  }

  throw new Error(`Failed to send memberId: ${memberId} to Yappli.`);
};
