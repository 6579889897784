// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppDownloadBanner__banner--vzmA1{align-items:center;background-color:#ededed;color:#000;display:flex;gap:4px;justify-content:space-between;padding:10px 12px 10px 0}.AppDownloadBanner__close--KY7Ym{align-self:stretch;font-size:18px;padding:0 2px 0 6px}.AppDownloadBanner__logo--cPJbp{align-items:center;background-color:#fff;border-radius:10px;display:flex;height:56px;justify-content:center;width:56px}.AppDownloadBanner__text--P4Upz{font-size:12px;font-weight:700;line-height:1.2}.AppDownloadBanner__download--pJLOy{background-color:#fff;border:1px solid #000;border-radius:6px;font-size:13px;font-weight:700;padding:4px 11px}@media only screen and (min-width:640px){.AppDownloadBanner__banner--vzmA1{display:none}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/organisms/AppDownloadBanner.vue","webpack://./assets/src/scss/base/_variables.scss"],"names":[],"mappings":"AA2CA,kCACE,kBAAA,CACA,wBAAA,CACA,UCbM,CDcN,YAAA,CACA,OAAA,CACA,6BAAA,CACA,wBAAA,CAOF,iCACE,kBAAA,CACA,cAAA,CACA,mBAAA,CAGF,gCACE,kBAAA,CACA,qBC/BM,CDgCN,kBAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,UAAA,CAGF,gCACE,cAAA,CACA,eAAA,CACA,eAAA,CAGF,oCACE,qBC9CM,CD+CN,qBAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAjCA,yCATF,kCAUI,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "AppDownloadBanner__banner--vzmA1",
	"close": "AppDownloadBanner__close--KY7Ym",
	"logo": "AppDownloadBanner__logo--cPJbp",
	"text": "AppDownloadBanner__text--P4Upz",
	"download": "AppDownloadBanner__download--pJLOy"
};
module.exports = ___CSS_LOADER_EXPORT___;
