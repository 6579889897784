






import { defineComponent, ref, computed } from '@vue/composition-api';
import HeaderHeartFill from '@/icon/header-heart-fill.svg?component';
import { useUserStore } from '@/js/lib/store/user';
import { useEventStore } from '@/js/lib/store/event';
import { likeEvent, unlikeEvent } from '@/js/lib/api/event';
import { loading } from '@/js/lib/ui';
import { signinSignup } from '@/js/lib/ui/signin-signup';

export default defineComponent({
  components: {
    HeaderHeartFill,
  },
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const eventStore = useEventStore();
    const isLoading = ref(false);
    const isLiked = computed(() => eventStore.likedEventIds.includes(props.eventId));
    const like = async () => {
      eventStore.addLikedEventId(props.eventId);
      await likeEvent({ eventId: props.eventId }).catch(() => {
        eventStore.removeLikedEventId(props.eventId);
      });
    };
    const unlike = async () => {
      eventStore.removeLikedEventId(props.eventId);
      await unlikeEvent({ eventId: props.eventId }).catch(() => {
        eventStore.addLikedEventId(props.eventId);
      });
    };
    const onClick = async () => {
      if (userStore.isAuthenticated) {
        if (isLoading.value) {
          return;
        }

        isLoading.value = true;
        await (isLiked.value ? unlike() : like());
        isLoading.value = false;
      } else {
        signinSignup({
          next: window.location.href,
          callback: async () => {
            await loading({
              asyncFunc: like,
              text: 'ログインしています',
            });
            window.location.reload();
          },
        });
      }
    };

    return {
      isLiked,
      onClick,
    };
  },
});
