import type { Topic } from '@/js/lib/_env';
import { info } from '@/js/lib/ui';
import { mountModal } from '@/js/lib/ui/helper/dynamic-modal';
import WelcomeModal from '@/js/components/organisms/modals/WelcomeModal.vue';
import EmailConfirmedModal from '@/js/components/organisms/modals/EmailConfirmedModal.vue';
import EmailConfirmModal from '@/js/components/organisms/modals/EmailConfirmModal.vue';
import PermissionModal from '@/js/components/permission/index.vue';
import SignupCompleteModal from '@/js/components/organisms/modals/SignupCompleteModal.vue';

export const startupTopics = (topics: Topic[]): Promise<unknown> => {
  const promises = topics.map((topic) => () => {
    if (topic.type === undefined) {
      return Promise.reject(new Error('a topic requires `type` attribute.'));
    }

    switch (topic.type) {
      case 'confirmationRequired': {
        return mountModal({ Component: EmailConfirmModal });
      }
      case 'emailConfirmed': {
        return mountModal({
          Component: EmailConfirmedModal,
          props: {
            fromNative: topic.props.fromNative,
          },
        });
      }
      case 'info': {
        return info(topic.props.text, { fadeOut: true });
      }
      case 'permissionRequired': {
        return mountModal({
          Component: PermissionModal,
          props: {
            permissionId: topic.props.permissionId,
          },
          canScroll: true,
        });
      }
      case 'signupComplete': {
        return mountModal({
          Component: SignupCompleteModal,
          props: {
            fromNative: topic.props.fromNative,
          },
        });
      }
      case 'warning': {
        return info(topic.props.text, { fadeOut: false });
      }
      case 'welcome': {
        return mountModal({ Component: WelcomeModal, props: { email: topic.props.email } });
      }
      default: {
        return Promise.reject(new Error(`Invalid topic type: ${topic.type}`));
      }
    }
  });

  // eslint-disable-next-line unicorn/no-array-reduce
  return promises.reduce((p, f) => p.then(f), Promise.resolve()); // promisesの中身を逐次実行する https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Using_promises#composition
};
