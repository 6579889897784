import axios from '@/js/lib/axios';
import { updateToken } from './update_token';
import { toCamelCaseKeys } from '@/js/lib/helper/localize-keys';

interface EventApiData {
  event_id: number;
  slug: string;
  title: string;
  description: string;
  zipcode: string;
  prefecture: string;
  address: string;
  venue: string;
  geometry: {
    lat: string;
    lng: string;
  };
  url: string;
  start_at: string;
  end_at: string;
  schedule_comment: string;
  contact: string;
  genres: string[];
  pictures: {
    filename: string;
    url: string;
  }[];
}

export interface EventData {
  eventId: number;
  slug?: string;
  title: string;
  description: string;
  zipcode: string;
  prefecture: string;
  address: string;
  venue: string;
  geometry: {
    lat: string;
    lng: string;
  };
  url: string;
  startDate: Date;
  endDate: Date;
  scheduleComment: string;
  contact: string;
  genres: string[];
  pictures: {
    path: string;
  }[];
}

export type EventDataForEdit = Omit<EventData, 'pictures'> & {
  pictures: {
    filename: string;
    url: string;
  }[];
};

interface EventLiking {
  eventId: string;
  liked: 0 | 1;
}

const translateForModel = (eventData: EventApiData): EventData | EventDataForEdit => {
  const translated = toCamelCaseKeys(eventData);

  translated.startDate = new Date(eventData.start_at);

  if (eventData.end_at) {
    translated.endDate = eventData.start_at === eventData.end_at ? undefined : new Date(eventData.end_at);
  } else {
    translated.endDate = undefined;
  }

  return translated;
};

const formatDate = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const getEvent = async ({ eventId, forEdit = false }: { eventId?: number; forEdit?: boolean } = {}): Promise<EventData | EventDataForEdit> => {
  const res = await axios.get('/api/event', {
    params: {
      event_id: eventId,
      for_edit: forEdit ? 1 : 0,
    },
  });
  return translateForModel(res.data.event);
};

export const saveEvent = async ({ eventId, title, description, zipcode, prefecture, address, venue, geometry, url, startDate, endDate, scheduleComment, contact, genres, pictures }: EventDataForEdit) => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append(
    'params',
    JSON.stringify({
      event_id: eventId,
      title,
      description,
      zipcode,
      prefecture,
      address,
      venue,
      geometry: {
        lat: geometry.lat ? String(geometry.lat) : undefined, // floatのままだと、PHP側で精度が出ない
        lng: geometry.lng ? String(geometry.lng) : undefined,
      },
      url,
      start_at: formatDate(startDate),
      end_at: endDate ? formatDate(endDate) : undefined,
      schedule_comment: scheduleComment,
      contact,
      genres,
      pictures,
    }),
  );
  params.append('token', token);
  const res = await axios.post('/api/event/save', params);
  if ('errors' in res.data) {
    throw res.data.errors;
  } else {
    return translateForModel(res.data.event) as EventDataForEdit;
  }
};

export const deleteEvent = async ({ eventId }: { eventId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('event_id', String(eventId));
  params.append('token', token);
  const res = await axios.post('/api/event/delete', params);
  return res.data;
};

export const likeEvent = async ({ eventId }: { eventId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('event_id', String(eventId));
  params.append('token', token);
  const res = await axios.post('/api/event/will', params);
  return res.data;
};

export const unlikeEvent = async ({ eventId }: { eventId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('event_id', String(eventId));
  params.append('token', token);
  const res = await axios.post('/api/event/unlike', params);
  return res.data;
};

export const isEventLiking = async ({ eventIds }): Promise<EventLiking[]> => {
  const res = await axios.get('/api/is-liked/events', {
    params: {
      event_ids: eventIds.join(','),
    },
  });
  return toCamelCaseKeys(res.data);
};

export const countEventLikes = async ({ eventIds }): Promise<Record<number, number>> => {
  const res = await axios.get<{ event_id: number; count: number }[]>('/api/like/count/event-likes', {
    params: {
      event_ids: eventIds.join(','),
    },
  });
  const result = {};
  if (Array.isArray(res.data)) {
    res.data.forEach((item) => {
      result[item.event_id] = item.count;
    });
  }
  return result;
};
