











import { defineComponent, ref } from '@vue/composition-api';
import { useCommonStore } from '@/js/lib/store/common';
import { getFromStorage, setToStorage } from '@/js/lib/helper/expirable-local-storage';

const KEY = 'close-app-download-banner';

export default defineComponent({
  setup() {
    const commonStore = useCommonStore();
    const isShow = ref(!getFromStorage({ key: KEY }) && !commonStore.isWebView);
    const sendEvent = () => {
      window.dataLayer?.push({
        event: 'app_banner_click',
      });
    };
    const onClose = () => {
      isShow.value = false;
      setToStorage({ key: KEY, value: true, expirationSeconds: -1 });
    };

    return {
      isShow,
      sendEvent,
      onClose,
    };
  },
});
