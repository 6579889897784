import { ref, computed, onMounted } from '@vue/composition-api';
import { type CombinedVueInstance, type VueConstructor } from 'vue/types/vue';
import Vue from 'vue';
import { uniqueId } from '@/js/lib/helper/unique-id';
import IModal from '@/js/components/molecules/IModal.vue';

interface Data {
  isShow: boolean;
}

interface Method {
  modalClose: () => void;
  onOverlayClick: () => void;
  onClose: () => void;
  onCancel: () => void;
  onOk: () => void;
  stopEvent: () => void;
}

interface Computed {
  [key: string]: never;
}
interface Props {
  textCancel: string;
  textOk: string;
  withCloseButton: boolean;
  withActionButton: boolean;
  withCancelButton: boolean;
  fadeOut: boolean;
}

type ModalVueInstance = CombinedVueInstance<Vue, Data, Method, Computed, Props>;

type BaseArgs = {
  Component?: VueConstructor;
  props?: Record<string, unknown>;
  slot?: string | string[];
  canScroll?: boolean;
};

export function mountModal({ Component, props, slot, canScroll }: BaseArgs): Promise<boolean>;
export function mountModal<T>({ Component, props, slot, asyncFunc, canScroll }: BaseArgs & { asyncFunc: () => Promise<T> }): Promise<T>;
export function mountModal<T>({ Component, props, slot, asyncFunc, canScroll }: BaseArgs & { asyncFunc?: () => Promise<T> } = {}): Promise<T | boolean> {
  const mountDiv = document.createElement('div');
  document.body.append(mountDiv);
  return new Promise((resolve, reject) => {
    const onClose = () => {
      resolve(false);
    };
    const onCancel = () => {
      resolve(false);
    };
    const onOk = () => {
      resolve(true);
    };

    new Vue({
      el: mountDiv,
      components: { Modal: Component || IModal },
      setup() {
        const modalComponent = ref<Vue>();
        const texts = computed(() => (Array.isArray(slot) ? slot : [slot]));

        onMounted(async () => {
          // 非同期処理のローディング用
          if (asyncFunc) {
            try {
              const res = await asyncFunc();
              resolve(res);
            } catch (error) {
              reject(error);
            } finally {
              const childModal = modalComponent.value.$refs.modal as ModalVueInstance;
              if (childModal?.modalClose) {
                childModal.modalClose();
              }
            }
          }
        });

        return {
          modalComponent,
          props,
          canScroll,
          texts,
          uniqueId,
          onClose,
          onCancel,
          onOk,
        };
      },
      template: `<modal v-on:close="onClose" v-on:cancel="onCancel" v-on:ok="onOk" v-bind="props" :lock-scroll="!canScroll" ref="modalComponent">
        <p v-for="text in texts" :key="uniqueId()" style="margin: 6px 0; white-space: pre-wrap;">{{ text }}</p>
      </modal>`,
    });
  });
}
